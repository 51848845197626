export default {
  // defaultBaseUrl: 'https://back.mhaoxiong.com',
  // defaultBaseUrl: 'http://192.168.3.105:10001',
  // h5Host: 'http://192.168.3.101',
  // shdHost: 'http://192.168.3.101:802',
  // PChost: 'http://test.pc.ikbh.top'

  defaultBaseUrl: '/prod-api',
  h5Host: '',
  shdHost: '/erp',
  PChost: ''

  // defaultBaseUrl:'/prod-api',
  // h5Host:'https://h5.yududs.com',
  // shdHost:'/erp',
  // PChost:'https://yududs.com'
}